import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";
import stylistData from '../../data/stylists.json';
import Instagram from '../../images/instagram.png'




const SingleStylist = () => {
    let params = useParams();

    const stylistName = Object.values(params);

    const stylistPage = stylistData.find(e => {
        return e.slug === stylistName.toString()
    })

    const workDays = stylistPage?.work_days.map(d => (
          <p key={d} className="day">{d}</p> 
    ))

    const skills = stylistPage?.specialties.map(s => (
          <p key={s} className="skills">{s}</p> 
    ))

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Fragment>
            <header className="stylist-area">
                <div className="container">
                    <div className="stylist-header-cnt">
                    <div className="single-stylist-item">
                        <img className="stylists-image" src={stylistPage.stylist_image} alt="" />
                        <div className="stylist-skills">
                            <p className="single-stylist-job">Skills</p>
                            <div className="stylist-skill-list">
                                {skills}
                            </div>
                        </div>
                    </div>
                    <div className="stylist-information">
                        <div className="stylist-name-container">
                            <h6 className="single-stylist-name">{stylistPage.first_name} {stylistPage.last_name}</h6>
                            <a className="instagram" rel="noopener noreferrer" target="_blank" href={`https://instagram.com/${stylistPage.instagram}`}>
                             <img className="instagram-stylist" src={Instagram} alt=""/>
                            </a>
                        </div>
                        <p className="single-stylist-job">Hair Stylist</p>
                        <p className="single-stylist-fact">{stylistPage.fun_fact}</p>
                        <div className="stylist-meta">
                            <p className="stylist-experience">{stylistPage.years_of_exp} years<p className="stylist-category">Experience</p> </p>
                            <div className="stylist-work">
                                <div className="stylist-days">
                                    { workDays } 
                                </div>
                                 <p className="stylist-category">Days I Work</p> 
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
             </header>
        </Fragment>
    )
}

export default SingleStylist;