import React, { Fragment } from 'react';
import About from './layout/About';
import Gallery from './layout/Gallery';
import HEM from './layout/HEM';
import Inspo from './layout/Inspo';
import Personalized from './layout/Personalized';
import Process from './layout/Process';
import Review from './layout/Reviews';
import Start from './layout/Start';
import SalonLocations from './SalonLocations';

const Home = () => {
    return (
        <Fragment>
            <header className="header-area">
                <div className="container">
                    <div className="header-cnt">
                        <p>Creators of the Habit Extension Method <br/> & The Perfect Blend</p>
                        <div className="habit-btn-containers">
                            <div className="book-container">
                                <a href="https://dashboard.boulevard.io/booking/businesses/2fb0d8b1-5dc2-4864-aefb-5ef8b8d22e90/widget#/visit-type" target="_blank" rel="noopener noreferrer" className="book-appointment">Book Appointment</a>
                            </div>
                            <div className="book-container">
                                <a href="https://habitshop.com/?ref=HabitSalon.com" target="_blank" rel="noopener noreferrer" className="book-appointment">Habit Shop</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <About/>
            <SalonLocations/>
            <Start/>
            <Review/>
            <HEM/>
            <Personalized/>
            <Process/>
            <Inspo/>
            <Gallery/>
        </Fragment>
    )
}

export default Home;