import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../css/grid.css';
import '../css/style.css';
import '../css/responsive.css';
import Nav from './navigation/Nav';
import Home from './Home';
import Footer from './Footer';
import StylistList from './stylists/StylistList';
import SingleStylist from './stylists/SingleStylist';
import Salons from './Salons';
import Learn from './Learn';
import Brands from './footer/Brands';
import Careers from './footer/Careers';
import Privacy from './footer/Privacy';
import Terms from './footer/Terms';
import Education from './Education';
import Travel from './Travel';
import Assistants from './Assistants';

const App = () => {
    return (
        <Fragment>
            <Router>
            <Nav/>
            <Routes>
                <Route  path='/' element={<Home />}/>
                <Route  path='/stylists' element={<StylistList />}/>
                <Route  path='/stylist/:slug' element={<SingleStylist />}/>
                <Route  path='/salons' element={<Salons />}/>
                <Route  path='/learn' element={<Learn />}/>
                <Route  path='/education' element={<Education />}/>
                <Route  path='/travel' element={<Travel />}/>
                <Route  path='/brands' element={<Brands />} />
                <Route  path='/careers' element={<Careers /> }/>
                <Route  path='/privacy' element={<Privacy />}/>
                <Route  path='/cancellation' element={ <Terms /> }/>
                <Route path='/assistants' element={ <Assistants /> }/>
                <Route path='/privacy' element={ <Privacy />} />
            </Routes>
            <Footer/>
            </Router>
        </Fragment>

    )
};

export default App;