import React from 'react';
import Consultation from '../layout/Consultation';
import InspoImage from '../../images/inspo.png';

const Inspo = () => {
    return (
        <div className="inspo-area">
            <div className="container">
                <div className="inspo-upper">
                    <h2>Looking For Inspo?</h2>
                    <img src={InspoImage} alt=""/>
                    <h4>Feeling inspired?</h4>
                    <p>See what girls in the past have done to get an idea of what you want for your next appointment. </p>
                    <a href="https://instagram.com/hairby_chrissy" target="_blank"  rel="noreferrer">VIEW OUR INSPO GALLERY</a>
                </div>
               <Consultation/>
            </div>
        </div>
    )
}

export default Inspo; 