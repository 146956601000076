import React from 'react';
import method2 from '../../images/method-2.jpg';

const Personalized = () => {
    return (
        <div className="method-area personal-area">
            <div className="row no-gutters">
                <div className="col-md-6 order2">
                    <div className="method-cnt personal-cnt">
                        <h4>Personalized Services For You</h4>
                        <p>No two heads are the same. <br/> This is why we want to get as much information as possible from you before your appointment like goal photos, your current hair photos and what you have done to your hair before coming into Habit. </p>
                        <p>This will give us all the information we need to know exactly what products we should use and how quickly we can reach your goals.</p>
                    </div>
                </div>
                <div className="col-md-6 order1">
                    <div className="method-item">
                        <img src={method2} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Personalized;