import React from 'react';
import serv1 from '../../images/salon1.jpg';
import serv2 from '../../images/serve-2.jpg';
import serv3 from '../../images/serv-3.jpg';

const Start = () => {
    return (
        <div className="service-area">
            <div className="container">
                <div className="serv-upper">
                    <h2>Start at the top</h2>
                    <h6>We believe everyone is beautiful.</h6>
                    <p>We help you acheive that look you want with the help of our <br/> talented team of hair stylists. </p>
                </div>
                <div className="serv-main">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="serv-item">
                                <img src={serv1} alt=""/>
                                <div className="serv-cnt">
                                    <p>Reach out to us via our live chat at the bottom right hand corner of the site</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="serv-item serv2-box">
                                <img src={serv2} alt=""/>
                                <div className="serv-cnt">
                                    <p>Share with us your inspo pictures & pictures of yourself</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4"> 
                            <div className="serv-item">
                                <img src={serv3} alt=""/>
                                <div className="serv-cnt">
                                    <p>Let our very talented Habit Hair Concierge match you with the perfect stylist for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;