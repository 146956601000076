import React from 'react';
import { Link } from 'react-router-dom';
import method1 from '../../images/method-1.jpg'

const HEM = () => {
    return (
        <div className="method-area">
            <div className="row no-gutters">
                <div className="col-md-6">
                    <div className="method-item">
                        <img src={method1} alt=""/>
                    </div>
                </div>
                <div className="col-md-6 method-right">
                    <div className="method-cnt">
                        <h4>Habit Extension Method</h4>
                        <p>Our award winning extension method has been refined and perfected over the last 7 years to provide the most comfortable (fit) in your hair.</p>
                        <p>Tell us about your lifestyle.</p>
                        <h3>Our 3 other methods may be better suited for you. </h3>
                        <ul>
                            <li>Habit Hidden Method - For the casual updo girl </li>
                            <li>Habit Active Method - For the sporty ponytail girl</li>
                            <li>Habit Clip-In Method - For the girl who wants complete control of when their extensions</li>
                        </ul>
                        <div className="cta-container">
                            <Link to='/learn' className="cta-btn"> Learn More </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HEM;