import React from 'react';

const Consultation = () => {
    return (
        <div className="consult-part">
            <span></span>
            <h2>Start a Consultation</h2>
            <p>During your complimentary consultation a Habit Provider will review the images you have sent of your goal photos and photos of your own hair. </p>
            <p>They will provide recommendations and then pair you with a Habit Stylist to schedule with. Scheduling may be done via our live chat but there may be times where you will talk with the stylists directly to schedule your appointment.</p>
            <a href="#" id="start-consult">START A CONSULT</a>
        </div>
    )
}

export default Consultation;