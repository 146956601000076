import React from 'react';

const Review = () => {
    return (
        <div className="habit-area">
        <div className="container">
            <div className="habit-upper">
                <h2>Why people love Habit</h2>
            </div>
            <div className="habit-slider">
                <div className="habit-item">
                    <p>This is by far the best salon I've ever been to. I get my haircut and colored here, I get my girls haircuts here I have never been disappointed. I have gone to several different stylists and always had great experiences.
                    I have never been treated with anything but extreme care and kindness. And same for my young children. They never make me feel rushed while I'm getting my hair done. They are so considerate of my appointment and they are willing to spend extra time to be sure I'm completely happy.
                    The front desk staff also never rushes me. When making appointments, I don't sense stress or impatience even when I am trying to schedule and reschedule my three daughters all at the same time. I find this extra impressive because that front desk can get busy. They somehow give each client their time and care.
                    <br/>
                    Thank you habit salon! You are my favorite!</p>
                    <h4>Rachelle Liddle</h4>
                    <h6>Arizona</h6>
                    <img className="left" src="images/left.png" alt=""/>
                    <img className="right" src="images/right.png" alt=""/>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Review;