import React, { useState, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Link } from 'react-router-dom';

const Assistants = () => {
    const videos = [
        { id: 'https://vimeo.com/733417158/d7ac116f9f', name: 'Welcome To Habit' },
        { id: 'https://vimeo.com/733416221/aab0f6803c', name: 'Habit Experience' },
        { id: 'https://vimeo.com/733416748/e05cae45df', name: 'Salon & Personal Assistants' },
        { id: 'https://vimeo.com/733416362/38673228e5', name: 'Salon Assistants' },
        { id: 'https://vimeo.com/733416402/e4fcfd9b34', name: 'Personal Assistants' },
        { id: 'https://vimeo.com/733416556/c9fdda4c0a', name: 'Front Desk' },
        { id: 'https://vimeo.com/733416811/c1f964acfc', name: 'Duties' },
        { id: 'https://vimeo.com/733416437/a12312e749', name: 'Stylists Commission' },
        { id: 'https://vimeo.com/733417005/d7308f4e91', name: 'Stylists' },
        { id: 'https://vimeo.com/733416319/f2200fbd25', name: 'Managers' }
      ];
    const [ currentAssistantVideo, setCurrentAssistantVideo ] = useState(0);


    const updateVideo = (v) => {
        setCurrentAssistantVideo(v);
        console.log(v);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const video = videos[currentAssistantVideo];
    console.log(video);

    const urlParams = new URLSearchParams(window.location.search);
    const assistantQueryParam = urlParams.get('assistant');
    const showContent = assistantQueryParam === 'employee';
    return(
        <header className="assistants-header-area">
            {showContent ? 
                <div className="assistant-videos-container">
                    <div className="select-video">
                        <div className="vimeo-container">
                                <Vimeo
                                    video={ video.id }
                                    autoplay
                                    responsive={true}
                                />  
                        </div>

                        <ul className="video-list">
                            <h6 className="video-title">Habit Roles</h6>
                            { videos.map((choice, index) => (
                                <a  
                                    key={index}
                                    className={`extension-list ${video === choice ? 'active-player' : ''} `}
                                    href={`#!/${index}`}
                                    onClick={() => updateVideo(index)}>
                                    <span>{index + 1}</span>
                                    {choice.name}
                                </a>

                            ))

                            }

                        </ul>
                    </div>
                </div>
            
            :
            <div className="assistant-videos-container">
                <p class="assistant-no-content">Nothing to see here. <Link to="/">Go back home</Link></p>
            </div>
            }
        </header>
    )
};

export default Assistants;