import React, { useState, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import LearnImage from '../images/learn.jpg'

const Learn = () => {
    const videos = [
        { id: 'https://vimeo.com/293407876', name: 'How HEM Is Applied' },
        { id: 'https://vimeo.com/293409150', name: 'How HEM Looks Installed' },
        { id: 'https://vimeo.com/293409502', name: 'How To Care For Extensions' },
        { id: 'https://vimeo.com/301914896', name: 'Shampoo & Conditioners' },
        { id: 'https://vimeo.com/293410551', name: 'Extension Removal' }
      ];
    const [ currentVideo, setCurrentVideo ] = useState(0);
   

    const updateVideo = (v) => {
        setCurrentVideo(v);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const video = videos[currentVideo];
    return(
        <header className="learn-header-area">
            <div className="learn-container">
                <img src={LearnImage} alt="hero" className="learn-image" />
                <div className="learn-header-cnt">
                    <h6>Learn How Our Extension Method Works</h6>
                    <p>
                        Watch the videos below to learn how the Habit Extension Method is installed, lays and is removed.
                        This will give you a clear understanding of how this method works and to help you decide if Habit Extensions
                        will be a good fit for you.
                    </p>
                </div>
            </div>
            <div className="learn-videos-container">
                <div className="select-video">
                    <div className="vimeo-container">
                            <Vimeo
                                video={video.id === undefined ? currentVideo : video.id}
                                autoplay
                                responsive={true}
                            />  
                    </div>

                    <ul className="video-list">
                        <h6 className="video-title">Extension Care</h6>
                        { videos.map((choice, index) => (
                            <a  
                                key={index}
                                className={`extension-list ${video === choice ? 'active-player' : ''} `}
                                href={`#!/${index}`}
                                onClick={() => updateVideo(index)}>
                                <span>{index + 1}</span>
                                {choice.name}
                            </a>

                        ))

                        }

                    </ul>
                </div>
            </div>
        </header>
    )
};

export default Learn;