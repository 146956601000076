import React, { useEffect } from 'react';


const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <>
            <div className="education-container">
                <h1 className="education-title">Our Privacy Policy</h1>
            </div>

            <div className="privacy-policy-container">
           <p>
                This Privacy Policy describes how Habit Salon ("we," "us," or "our") collects, uses, and shares information from individuals who visit our website, use our services, or otherwise interact with us ("Consumers"). 
                We are committed to protecting your privacy and ensuring the security of your Personally Identifiable Information (PII). Please read this Privacy Policy carefully to understand how we handle your information.
                By accessing or using our website or services, you consent to the practices described in this Privacy Policy.  We are committed to protecting your privacy and ensuring the security of your Personally Identifiable Information (PII).
                 Please read this Privacy Policy carefully to understand how we handle your information.
                By accessing or using our website or services, you consent to the practices described in this Privacy Policy.
            </p> 
            <br/>
            <br/>
            <p>1. Information We Collect:</p>
            <br/>
             <p>We collect the following types of information:
            Personal Information: When you visit our website or use our services, we may collect personal information that you provide to us voluntarily. This may include your name, email address, phone number, and any other information you choose to provide.
            We may use your Personal Data to contact you with newsletters, marketing, and/or promotional materials that may be of interest to you. Message frequency may vary. Message & data rates may apply. You may opt out of receiving any, or all, of these communications from us by contacting us, or by responding STOP or UNSUBSCRIBE to any SMS message we send.
            Automatically Collected Information: We may also collect certain information automatically when you use our website, such as your IP address, browser type, device type, and the pages you visit.
            Cookies: We may use cookies and similar technologies to collect information about your browsing behavior. You can learn more about our use of cookies in our Cookie Policy.</p> 
            <p>2. How We Use Your Information</p>
            <br/>
            <p>
                We use the information we collect for the following purposes:
                Providing Services: We may use your information to provide you with our salon services and to respond to your inquiries.
                Communication: We may use your contact information to send you important updates and promotional materials. You can opt out of receiving promotional communications at any time.
                Website Improvement: We may use your information to analyze and improve our website's functionality, content, and user experience.
            </p>
            <br/>
            <br/>
            
            <p>3. Sharing Your Information</p>
            <br/>
            <p>We do not share or sell your Personally Identifiable Information to third parties for marketing purposes. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. However, we may share your information in the following circumstances:
            Business Operations: We may share your information with third-party service providers who assist us in performing essential business functions, such as payment processing, customer support, and website maintenance.
            Compliance with Legal Requirements: We may disclose your information when required by law or to comply with legal processes.
            Sale or Merger: In the event of a sale, merger, or acquisition of our business, we may transfer your information to the acquiring company, but your data will continue to be protected in accordance with this Privacy Policy.</p>
            <br/>
            <br/>
           <p>4. Your Choices and Rights</p> 
           <br/>
           <p>You have the following rights regarding your information:
            Access: You can request access to the personal information we hold about you.
            Rectification: You can request corrections to inaccuracies in your personal information.
            Deletion: You can request the deletion of your personal information, subject to legal obligations.
            Data Portability: You can request a copy of your personal information in a structured, machine-readable format.</p>
            <br/>
            <br/>
             <p>5. Changes to this Privacy Policy</p> 
             <br/>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post the updated Privacy Policy on our website, and the "Last Updated" date at the top of this page will be revised accordingly. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
            </p> 
            <br/>
            <br/>
            <p>5. Contact Us</p> 
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact our corporate office at:
            Habit Salon </p>
            <br/>
            <br/>
            <p> 4331 E Baseline Rd, Gilbert, AZ 85234</p>
           <p>Telephone: +1 (480) 462-5692</p>
            <p>Email: education@habitsalon.com</p>
            





            </div>
        </>
    )
};

export default Privacy;