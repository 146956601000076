import React from 'react';
import { Link } from 'react-router-dom';
import method3 from '../../images/method-3.jpg';

const Process = () => {
    return (
        <div className="method-area proces-area">
            <div className="row no-gutters">
                <div className="col-md-6">
                    <div className="method-item">
                        <img src={method3} alt=""/>
                    </div>
                </div>
                <div className="col-md-6 method-right">
                    <div className="method-cnt proces-cnt">
                        <h4>The Process is the Best Part</h4>
                        <p>Every Habit stylist loves an incredible transformation. But sometimes those transformations take time and a few appointments.</p>
                        <p>Our commitment to you is that we can help you achieve all of your hair goals, even if it takes a few appointments to get there.</p>
                        <div className="cta-container">
                            <Link to='/stylists' className="cta-btn"> Meet our stylists </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Process;